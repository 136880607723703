<template>
	<div class='container'>
		<div class="title">
      <span class="inner_title">审核列表</span>
    </div>
    <div class="panel_wrap">
      <span class="one_panel" :class="{ active: status == 'WAIT_AUDIT' }" @click="switchPanel('WAIT_AUDIT')">待审核</span>
      <span class="one_panel" :class="{ active: status =='SUCCESS' }" @click="switchPanel('SUCCESS')">已通过</span>
      <span class="one_panel" :class="{ active: status == 'FAILED' }" @click="switchPanel('FAILED')">未通过</span>
    </div>
    <div class="cont_container">
      <template>
        <el-table class="table" 
          v-loading="loading"  
          :element-loading-text="loadingText" 
          element-loading-spinner="el-icon-loading"
          :element-loading-background="loadingColor" 
          :data="shenHeList" 
          :header-cell-style="TableTitleBackColor" 
          :row-style="tableCss" 
          :highlight-current-row="true" 
          :cell-style="cellStyle" >
          <el-table-column type="index" label="序号">
          </el-table-column>
          <el-table-column prop="building_number" label="楼栋">
          </el-table-column>
          <el-table-column prop="unit_number" label="单元">
          </el-table-column>
          <el-table-column prop="house_number" label="房屋">
          </el-table-column>
          <el-table-column prop="user_name" label="姓名">
          </el-table-column>
          <el-table-column prop="sex" label="性别">
          </el-table-column>
          <el-table-column prop="type_txt" label="身份类型">
          </el-table-column>
          <el-table-column prop="mobile" label="联系方式">
          </el-table-column>
          <el-table-column prop="id_number" label="身份证号">
          </el-table-column>
          <el-table-column v-if="status == 'WAIT_AUDIT'" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="doAudit(scope.row,'PLATFORM_AUDIT_SUCCESS')">通过</el-button>
              <el-button type="primary" size="mini" @click="doAudit(scope.row,'PLATFORM_AUDIT_FAILED')">不通过</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
		<el-pagination style="width: 100%;text-align: center;" background layout="prev, pager, next" :page-count="last_page"
		 :current-page="page" @current-change="pageChange">
		</el-pagination>
	</div>
</template>
<script>
	import tableCss from '../../../style/tableCss.js';
	import {
		mapState
	} from "vuex";
  import {getShenHeListFn,doAuditFn} from '@/api/shenhe.js'
	export default {
		props: [],
		data() {
			return {
        status:'WAIT_AUDIT',
				loading: false,
				page: 1,
				page_size: 10,
				last_page: 0,

        shenHeList:[]
			}
		},
		computed: {
			...mapState(['loadingColor', 'loadingText'])
		},
    created() {
    },
    mounted() {
			this.getShenHeList();
		},
		methods: {
      //操作审核
      doAudit(row,status){
        let params = {
          id:row.id,
          api_token:localStorage.getItem('token1'),
          status:status
        }
        this.$confirm('确定操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          doAuditFn(params).then(res => {
            if (res.code == 200){
              // this.$message('操作成功')
              this.getShenHeList()
            }
          })
        }).catch(() => {
          console.log('已经取消')
        });
      },
      //切换审核状态
      switchPanel(status) {
        this.status = status
        this.getShenHeList()
			},
      //获取审核列表
			getShenHeList() {
				this.loading = true;
				getShenHeListFn({
          api_token:localStorage.getItem('token1'),
					page: this.page,
					page_size: this.page_size,
          status:this.status
				}).then(res => {
					this.shenHeList = res.datas.data;
					this.last_page = res.meta.last_page;
					this.loading = false;
				})
			},
			//页数改变
			pageChange(page) {
				this.page = page;
				this.getShenHeList();
			},
			//表头样式
			TableTitleBackColor() {
				return tableCss.tableTitleBackColor();
			},
			//表格样式
			tableCss() {
				return tableCss.tableCss();
			},
			//单元格样式
			cellStyle() {
				return tableCss.cellStyle();
			},
		},
		activated() {

		}
	}
</script>
<style scoped lang='scss'>
	@import url("../../../style/title.css");
  .panel_wrap {
		display: flex;
		justify-content: flex-start;
    padding: 20px;
    .one_panel {
      // background-color: #f5f5f6;
      padding: 8px 40px;
      border: 1px solid #f5f5f6;
      cursor: pointer;
      &:nth-child(1){
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:nth-child(3){
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .active {
      border: 1px solid #395EDF;
      color: #395EDF;
      background-color: #DEE7FC;
    }
	}
</style>
