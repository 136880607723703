import request from '@/api/request.js'

//获取审核列表
export function getShenHeListFn (data){
  return request({
    url:'/village/occupant_apply',
    method:'get',
    params:data
  })
}
//操作审核
export function doAuditFn (data){
  return request({
    url:'/village/occupant_apply/'+ data.id +'/do_audit',
    method:'post',
    data:data
  })
}
